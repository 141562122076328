import React from 'react';

// Componente para renderizar contenido basado en el formato JSON
const RichTextRenderer = ({ content }) => {
  // Función auxiliar para aplicar estilos de texto
  const renderStyledText = (child) => {
    let styledText = child.text;

    // Si es un enlace, lo renderizamos directamente aquí
    if (child.type === 'link') {
      return (
        <a key={child.url} href={child.url} target="_blank" rel="noopener noreferrer">
          {child.children.map((linkChild) => renderStyledText(linkChild))}
        </a>
      );
    }

    // Aplica los estilos en orden: negrita, cursiva, y subrayado
    if (child.bold) {
      styledText = <strong>{styledText}</strong>;
    }
    if (child.italic) {
      styledText = <em>{styledText}</em>;
    }
    if (child.underline) {
      styledText = <u>{styledText}</u>;
    }

    return styledText;
  };

  const renderChildren = (children, indentLevel = 0) => {
    if (children.type === 'list') {
      // Si es una lista, determinamos si es ordenada o desordenada
      const ListTag = children.format === 'ordered' ? 'ol' : 'ul';
      return (
        <ListTag key={indentLevel}>
          {renderChildren(children.children, indentLevel + 1)}
        </ListTag>
      );
    }
    return children.map((child, index) => {
      if (child.type === 'list') {
        // Si es una lista, determinamos si es ordenada o desordenada
        const ListTag = child.format === 'ordered' ? 'ol' : 'ul';
        return (
          <ListTag key={index}>
            {renderChildren(child.children, indentLevel + 1)}
          </ListTag>
        );
      } else if (child.type === 'list-item') {
        // Renderiza un item de lista
        return (
          <li key={index}>
            {renderChildren(child.children, indentLevel)}
          </li>
        );
      } else if (child.type === 'text' || child.type === 'link') {
        // Renderiza texto o enlaces
        return renderStyledText(child);
      }
      return null;
    });
  };

  return (
    <>
      {content?.map((block, index) => {
        switch (block.type) {
          case 'list':
            return renderChildren(block);

          case 'heading':
            const HeadingTag = `h${block.level}`;
            return (
              <HeadingTag key={index}>
                {block.children.map((child) => renderStyledText(child))}
              </HeadingTag>
            );

          case 'quote':
            return (
              <blockquote key={index}>
                {block.children.map((child) => renderStyledText(child))}
              </blockquote>
            );

          case 'paragraph':
            return (
              <p key={index}>
                {block.children.map((child) => renderStyledText(child))}
              </p>
            );

          default:
            return null; // Manejar tipos no reconocidos
        }
      })}
    </>
  );
};

export default RichTextRenderer;
